import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { PlainBtn, PrimaryBtn, SecondaryBtn } from '@/buttons';
import { ReactComponent as MenuIcon } from '@a/icons/burger.svg';
import { ReactComponent as CalendarIcon } from '@a/icons/calendar.svg';
import { ReactComponent as ChevronIcon } from '@a/icons/chevron.svg';
import { ReactComponent as CloseIcon } from '@a/icons/close.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as BackIcon } from '@a/icons/right-arrow-circle.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import useRouteChange from '@s/hooks/useRouteChange';
import useWindowSize from '@s/hooks/useWindowSize';
import { Fragment, useEffect, useState } from 'react';
import { HomeLogo, scrollToSection } from './Nav';
import { navBtnStyles, navLinkStyles } from './NavLinkStyles';
import { NavLinksTypes, navLinks, siteInfo } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexSpace, flexStart, px } from './styles/classes';

const overlayStyles = css`
    display: none;
`;

const contentStyles = css`
    ${flexStart};
    ${px};
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.gray6};
    padding-top: 12px;
    padding-bottom: 32px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > nav {
        ${flexStart};
        flex-direction: column;
        margin: 0 auto;
        max-width: max-content;
        overflow-y: auto;
        padding-right: 10px;

        > *:last-child {
            margin-bottom: 6px;
        }
    }

    &[data-state='open'] {
        animation: slideIn 300ms ease-in;
    }

    &[data-state='closed'] {
        animation: slideOut 300ms ease-out;
    }

    @keyframes slideIn {
        from {
            transform: translateX(100vw);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(100vw);
        }
    }
`;

const Top = styled.div`
    ${flexSpace};
    width: 100%;
    margin-bottom: 32px;

    > button {
        &[aria-label='close sub nav menu'] {
            color: ${({ theme }) => theme.colors.gray1};
            text-transform: capitalize;
            font-weight: 400;

            > svg {
                transform: scaleX(-1);
                margin-right: 16px;
                flex-shrink: 0;
                width: 24px;
                height: 24px;

                path {
                    fill: ${({ theme }) => theme.colors.gray1};
                }

                circle {
                    stroke: ${({ theme }) => theme.colors.gray1};
                }
            }
        }

        &[aria-label='close nav menu'] {
            margin-left: auto;
        }
    }

    @media (min-height: 575px) {
        margin-bottom: 64px;
    }

    @media (min-height: 675px) {
        margin-bottom: 96px;
    }

    @media (min-height: 775px) {
        margin-bottom: 128px;
    }
`;

type MobNavProps = {
    text?: string;
    links?: NavLinksTypes;
    allDialogsClosed: boolean;
    closeAllDialogs: () => void;
};

export const MobNav = ({
    text,
    links = navLinks,
    allDialogsClosed,
    closeAllDialogs,
}: MobNavProps) => {
    const [open, setOpen] = useState(false);

    const { width } = useWindowSize();

    useRouteChange(setOpen);

    const { title, category } = useLocationData();

    const contactScroll = () => {
        setOpen(false);
        setTimeout(() => scrollToSection('#contact-us'), 100);
    };

    useEffect(() => {
        setOpen(false);
    }, [allDialogsClosed]);

    const currentCategory = category === text || title === text;

    return width < 1024 ? (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogOpen>
                {text ? (
                    <PlainBtn
                        css={navBtnStyles}
                        className={currentCategory ? 'current-nav-category' : ''}
                    >
                        {text} <ChevronIcon />
                    </PlainBtn>
                ) : (
                    <PlainBtn
                        aria-label="open navigation menu"
                        css={css`
                            @media (min-width: 1024px) {
                                display: none;
                            }
                        `}
                    >
                        <MenuIcon />
                    </PlainBtn>
                )}
            </DialogOpen>

            <DialogContent
                contentStyles={contentStyles}
                overlayStyles={overlayStyles}
                title="mobile nav menu"
            >
                <Top>
                    {text ? (
                        <DialogClose>
                            <PlainBtn aria-label="close sub nav menu">
                                <BackIcon /> {text}
                            </PlainBtn>
                        </DialogClose>
                    ) : (
                        <HomeLogo />
                    )}

                    <PlainBtn onClick={closeAllDialogs} aria-label="close nav menu">
                        <CloseIcon />
                    </PlainBtn>
                </Top>

                <nav>
                    {links.slice(0, -1).map((data, i) =>
                        data.links ? (
                            <MobNav
                                text={data.text}
                                links={data.links}
                                key={i}
                                allDialogsClosed={allDialogsClosed}
                                closeAllDialogs={closeAllDialogs}
                            />
                        ) : (
                            <CLink to={data.link} css={navLinkStyles} key={i}>
                                {data.text}
                            </CLink>
                        )
                    )}
                </nav>
                {text ? (
                    ''
                ) : (
                    <Fragment>
                        <PrimaryBtn
                            as={CLink}
                            to={siteInfo.phone.link}
                            white
                            css={css`
                                margin-top: auto;
                                margin-bottom: 24px;
                            `}
                        >
                            <PhoneIcon /> Call Us - {siteInfo.phone.text}
                        </PrimaryBtn>
                        <SecondaryBtn
                            onClick={contactScroll}
                            white
                            css={css`
                                width: 284.66px;
                            `}
                        >
                            <CalendarIcon /> contact us
                        </SecondaryBtn>
                    </Fragment>
                )}
            </DialogContent>
        </Dialog>
    ) : (
        <Fragment />
    );
};
